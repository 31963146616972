import React, { Component } from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { curryN, propOr } from 'ramda';
import { withStyles } from '@material-ui/core/styles';
import ArrowBackIosIcon from '@material-ui/icons/ArrowBackIos';
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import LazyLoad from 'react-lazyload';
import Image from 'next/image';

const styles = (theme) => ({
  productImage: {
    overflow: 'hidden',

    '&:hover $arrow': {
      opacity: 1,
      visibility: 'visible',
    },
  },
  mediaWrapper: {
    position: 'relative',

    '&:before': {
      content: '""',
      display: 'block',
      paddingBottom: '100%',
    },

    '& .lazyload-wrapper ': {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
    },
  },
  media: {
    userDrag: 'none',
    userSelect: 'none',
    height: '100%',
    cursor: 'pointer',
    overflow: 'hidden',
    width: '100%',
    objectFit: 'cover',
    display: 'block',
  },
  slider: {
    boxSizing: 'border-box',
    position: 'relative',
    width: '100%',

    '&:after': {
      content: '""',
      position: 'absolute',
      bottom: 0,
      height: 30,
      background:
        'linear-gradient(0deg, rgba(97,97,97,1) 0%, rgba(255,255,255,0) 100%)',
    },
  },
  slickDots: {
    display: 'flex',
    bottom: '15px',
    position: 'absolute',
    width: '100%',
    margin: 0,
    justifyContent: 'center',
    alignItems: 'center',
  },
  dotButton: {
    cursor: 'pointer',
    padding: 10,
    position: 'relative',

    '&:before': {
      color: 'transparent',
      margin: '0 !important',
      opacity: '0.6 !important',
      width: '8px',
      height: '8px',
      content: '""',
      borderRadius: '50%',
      background: '#fff',
      position: 'absolute',
      top: '50%',
      left: '50%',
      transform: 'translate(-50%, -50%)',
      boxShadow: '0 0 0 1px #565656',
    },
  },
  dotButtonActive: {
    '&:before': {
      opacity: '1 !important',
      width: '10px',
      height: '10px',
    },
  },
  arrow: {
    display: 'flex',
    width: 46,
    height: '100%',
    position: 'absolute',
    top: 0,
    zIndex: 2,
    background: 'rgba(0, 0, 0, 0.22)',
    cursor: 'pointer',
    color: '#fff',
    alignItems: 'center',
    justifyContent: 'center',
    transition: 'all .3s',
    opacity: 0,
    visibility: 'hidden',

    '&:hover': {
      // background: "rgba(0, 0, 0, 0.47)"
    },
  },
  arrowLeft: {
    left: 0,
  },
  arrowRight: {
    right: 0,
  },
  imageSlide: {
    transition: 'all .3s',
  },
  slideItem: {
    display: 'inline-block',
    verticalAlign: 'middle',
  },
  productVariant: {
    margin: 3,
    outline: 'none !important',
    padding: '3px 5px',
    position: 'relative',

    '&:after': {
      background: '#c1c1c1',
      content: '""',
      position: 'absolute',
      height: '60%',
      top: '50%',
      transform: 'translateY(-50%)',
      right: 0,
      width: 1,
    },
  },
  productVariantSelected: {
    borderBottom: `3px solid ${theme.palette.primary.main}`,
  },
});

class ProductImage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      selectedIdx: 0,
    };
  }

  handleSlideChange = curryN(2, (index, e) => {
    e.stopPropagation();
    this.setState({
      selectedIdx: index,
    });
  });

  handleForward = (e) => {
    e.stopPropagation();

    this.setState((prev) => ({
      selectedIdx: prev.selectedIdx + 1,
    }));
  };

  handleBackward = (e) => {
    e.stopPropagation();

    this.setState((prev) => ({
      selectedIdx: prev.selectedIdx - 1,
    }));
  };

  render() {
    const { classes, product } = this.props;
    const images = propOr(['/static/images/image-default.jpg'], 'images', product);

    return (
      <div className={clsx(classes.productImage)}>
        <div className={classes.slider}>
          <div
            style={{
              transform: `translateX(-${(this.state.selectedIdx / images.length) * 100
                }%)`,
              width: `${images.length * 100}%`,
            }}
            className={classes.imageSlide}
          >
            {images.map((image, i) => (
              <div
                key={i}
                style={{ width: `${100 / images.length}%` }}
                className={clsx(classes.mediaWrapper, classes.slideItem)}
              >
                <LazyLoad once offset={200}>
                  <Image src={image} className={classes.media} fill alt="Product Image" />
                </LazyLoad>
              </div>
            ))}
          </div>

          <div className={classes.slickDots}>
            {images.map((_, i) => (
              <div
                key={i}
                className={clsx(classes.dotButton, {
                  [classes.dotButtonActive]: i === this.state.selectedIdx,
                })}
                onClick={this.handleSlideChange(i)}
                onMouseDown={(e) => e.stopPropagation()}
              />
            ))}
          </div>

          {this.state.selectedIdx > 0 && (
            <div
              className={clsx(classes.arrow, classes.arrowLeft)}
              onClick={this.handleBackward}
              onMouseDown={(e) => e.stopPropagation()}
            >
              <ArrowBackIosIcon />
            </div>
          )}

          {this.state.selectedIdx < images.length - 1 && (
            <div
              className={clsx(classes.arrow, classes.arrowRight)}
              onClick={this.handleForward}
              onMouseDown={(e) => e.stopPropagation()}
            >
              <ArrowForwardIosIcon />
            </div>
          )}
        </div>
      </div>
    );
  }
}

ProductImage.defaultProps = {
  product: {},
};

ProductImage.propTypes = {
  classes: PropTypes.object.isRequired,
  product: PropTypes.object,
};

export default withStyles(styles)(ProductImage);
