import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SButton } from './components';
import { colors } from 'common/constants';

const StyledButton = styled(SButton)`
  background: ${colors.secondary} !important;
  color: white !important;
  :hover {
    background: ${colors.secondaryDark} !important;
    color: white !important;
    box-shadow: 0px 2px 4px -1px rgba(0, 0, 0, 0.2),
      0px 4px 5px 0px rgba(0, 0, 0, 0.14), 0px 1px 10px 0px rgba(0, 0, 0, 0.12) !important;
  }
`;
const PrimaryButton = (props) => {
  return <StyledButton {...props}>{props.children}</StyledButton>;
};

export default PrimaryButton;

PrimaryButton.defaultProps = {
  children: PropTypes.any,
};

PrimaryButton.propTypes = {
  children: PropTypes.any,
};
