const styles = (theme) => ({
  popup: {
    width: '100%',
    ' & .MuiDialog-paper': {
      maxWidth: '500px',
      [theme.breakpoints.down('xs')]: {
        width: '100%',
        margin: '15px',
      },
    },
  },
  createPopper: {
    zIndex: 1305,
    width: '180px',
    '& .MuiPopover-paper': {
      maxWidth: '100%',
      width: '100%'
    }
  },
  title: {
    padding: '10px 24px',

    [theme.breakpoints.down('xs')]: {
      padding: '10px 15px',
    },
  },
  dialogContent: {
    maxWidth: '500px',
    minWidth: '400px',
    width: '100%',
    padding: 0,

    [theme.breakpoints.down('xs')]: {
      minWidth: '100%',
    },
  },
  contentWrap: {
    padding: '24px',
  },
  dialogTitle: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
  },
  closeButton: {
    [theme.breakpoints.down('xs')]: {
      padding: '4px',
    },
  },
  createListBtn: {
    textTransform: 'none',
    color: theme.palette.primary.main,
    borderColor: theme.palette.primary.main,
    width: '180px',
    '&:hover': {
      backgroundColor: 'rgb(233, 233, 253)',
    },
  },
  saveBtn: {
    textTransform: 'none',
    color: 'white',
    backgroundColor: theme.palette.primary.main,
    marginLeft: '10px',

    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
    },
  },
  actionsGroup: {
    padding: '16px 24px',
    display: 'flex',
    justifyContent: 'flex-end',
  },
  spinIcon: {
    color: 'white',
    marginRight: '10px',
  },
  loadingIcon: {
    color: theme.palette.primary.main,
  },
  loadingIconWrap: {
    display: 'flex',
    justifyContent: 'center',
    margin: '20px',
  },
  menuList: {
    display: 'flex',
    alignItems: 'center',
    '& .MuiListItemIcon-root': {
      minWidth: '35px'
    },
    '& .icon': {
      width: '24px !important',
      height: '24px !important'
    }
  }
});

export default styles;
