import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SButton } from './components';

const StyledButton = styled(SButton)`
    // background: transparent;
    // color: rgba(0,0,0,0.6) !important;
    / border:1px solid rgba(0,0,0,0.3) !important;
    background: ${(props) =>
    props.on ? 'rgba(202, 106, 119, 0.2) !important' : 'transparent'};
    color: ${(props) =>
    props.on ? '#C6292E !important' : 'rgba(0,0,0,0.6) !important'};
    border: ${(props) =>
    props.on
      ? '1px solid #C6292E !important'
      : '1px solid rgba(0,0,0,0.3) !important'};
    svg{
        font-size:25px !important;
    }
    :hover{
        background: transparent;
        color:rgba(0,0,0,0.7);
        border:1px solid rgba(0,0,0,0.7);

    }
    // :focus{
    //   background: rgba(202, 106, 119, 0.2) !important;
    //   border: 1px solid #C6292E !important;
    //   color:#C6292E !important;
    // }
`;

const PrimaryButton = (props) => {
  return (
    <StyledButton
      {...props}
      on={props.on}
      startIcon={props.icon}
      icon={props.icon}
    >
      {props.children}
    </StyledButton>
  );
};

PrimaryButton.defaultProps = {
  children: PropTypes.any,
  icon: PropTypes.any,
  on: PropTypes.bool,
};

PrimaryButton.propTypes = {
  children: PropTypes.any,
  icon: PropTypes.any,
  on: PropTypes.bool,
};

export default PrimaryButton;
