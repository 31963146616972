import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'redux';
import Router from 'next/router';

import Dialog from '@material-ui/core/Dialog';
import DialogContent from '@material-ui/core/DialogContent';
import DialogTitle from '@material-ui/core/DialogTitle';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import {
  Divider,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Checkbox,
  Radio,
  FormControlLabel,
  RadioGroup
} from '@material-ui/core';

import { withStyles } from '@material-ui/core/styles';
import styles from './styles';
import { curry, curryN, includes, reject, values } from 'ramda';
import {
  addProductToIntegration,
  enqueueSnackbar,
  getIntegrationPages,
  getProductIntegrations,
} from '/common/store/actions';
import { connect } from 'react-redux';
import { selectIntegrationPages } from '/common/store/selector/integrations';
import { integrationData } from '../../../constants';

const mapStateToProps = (state) => ({
  pages: selectIntegrationPages(state),
});

const mapDispatchToProps = (dispatch) => {
  return {
    getIntegrationPages: () => dispatch(getIntegrationPages()),
    getProductIntegrations: (id) => dispatch(getProductIntegrations(id)),
    addProductToIntegration: (payload) =>
      dispatch(addProductToIntegration(payload)),
    enqueueSnackbar: (args) => dispatch(enqueueSnackbar(args)),
  };
};

class MoveProductToIntegrationListDialog extends Component {
  constructor(props) {
    super(props);
    this.menuRef = React.createRef();
  }

  state = {
    selectedIntegrations: [],
    productIntegrations: [],
    isSaving: false,
    isLoading: false,
    currentIntegration: null,
  };

  handleToggleSelect = curryN(2, (code) => {
    const data = includes(code, this.state.selectedIntegrations)
      ? reject((item) => code === item, this.state.selectedIntegrations)
      : [...this.state.selectedIntegrations, code];
    this.setState({
      selectedIntegrations: data,
    });
  });

  isSelected = (code) => {
    const data = this.state.selectedIntegrations;
    return Boolean(data.filter((item) => String(item) === String(code)).length);
  };

  preventClick = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
  };

  handleSave = (evt) => {
    const addList = this.state.selectedIntegrations.filter(
      (item) => !this.state.productIntegrations.includes(item),
    );
    const removeList = this.state.productIntegrations.filter(
      (item) => !this.state.selectedIntegrations.includes(item),
    );

    if (addList.length || removeList.length) {
      this.setState({
        isSaving: true,
      });

      const data = {
        id: this.props.product.id,
        type: this.props.product.type,
        update: {},
        bulk: {
          add_code: addList,
          remove_code: removeList,
        },
      };

      this.props
        .addProductToIntegration(data)
        .catch(() => {
          this.handleError();
        })
        .finally(() => {
          this.setState({
            isSaving: false,
          });
        });
      this.props.handleClose(evt);
    }
  };

  handleCreateNewIntegration = () => {
    Router.push(`/account/new-integration`);
  };

  handleChooseIntegration = curry((code, event) => {
    if (this.menuRef.current && this.menuRef.current.contains(event.target)) {
      return;
    }

    this.setState({
      isOpenMenu: false,
      currentIntegration: code,
    });

    Router.push(`/account/new-integration?in=${code}`);
  });

  componentDidMount() {
    this.setState({
      isLoading: true,
    });

    Promise.all([
      !this.props.pages.length ? this.props.getIntegrationPages() : null,
      this.props.getProductIntegrations(this.props.product.id).then((res) => {
        if (res) {
          this.setState({
            selectedIntegrations: values(res).map((item) => item.code),
            productIntegrations: values(res).map((item) => item.code),
          });
        }
      }),
    ])
      .catch(() => {
        this.handleError();
      })
      .finally(() => {
        this.setState({
          isLoading: false,
        });
      });
  }

  handleError = () => {
    this.props.enqueueSnackbar({
      message: 'Something went wrong. Please try again',
      options: {
        key: new Date().getTime() + Math.random(),
        variant: 'error',
      },
    });
    setTimeout(() => {
      this.props.handleClose();
    }, 2000);
  };

  render() {
    const { classes, isOpen, handleClose, pages } = this.props;

    return (
      <>
        <Dialog
          className={classes.popup}
          onClick={this.preventClick}
          onClose={handleClose}
          aria-labelledby="customized-dialog-title"
          open={isOpen}
        >
          <DialogTitle id="form-dialog-title" className={classes.title}>
            <div className={classes.dialogTitle}>
              <div>Save to a list</div>
              <IconButton
                aria-label="close"
                className={classes.closeButton}
                onClick={handleClose}
              >
                <CloseIcon />
              </IconButton>
            </div>
          </DialogTitle>
          <Divider />
          <DialogContent className={classes.dialogContent}>
            <div className={classes.contentWrap}>
              <div>
                Choose which of the lists you would like to place your favorite
                property
              </div>
              <div>
                <Choose>
                  <When condition={this.state.isLoading}>
                    <div className={classes.loadingIconWrap}>
                      <CircularProgress
                        className={classes.loadingIcon}
                        size={20}
                      />
                    </div>
                  </When>
                  <Otherwise>
                    <List disablePadding className={classes.nestedList}>
                      {pages.map((integration) => {
                        return (
                          <ListItem
                            key={integration.id}
                            role={undefined}
                            dense
                            button
                            onClick={this.handleToggleSelect(integration.code)}
                          >
                            <ListItemIcon>
                              <Checkbox
                                edge="start"
                                tabIndex={-1}
                                disableRipple
                                checked={this.isSelected(integration.code)}
                              />
                            </ListItemIcon>
                            <ListItemText
                              id={integration.id}
                              primary={integration.code}
                            />
                          </ListItem>
                        );
                      })}
                    </List>
                  </Otherwise>
                </Choose>
              </div>
              <div>Or setup a new integration</div>
              <div>
                <List disablePadding className={classes.nestedList}>
                  <RadioGroup
                    aria-label="gender"
                    name="gender1"
                    value={this.state.currentIntegration}
                  >
                    {integrationData.map((integration) => {
                      return (
                        <ListItem
                          key={integration.id}
                          role={undefined}
                          dense
                          button
                          onClick={this.handleChooseIntegration(
                            integration.code,
                          )}
                        >
                          <FormControlLabel
                            value={integration.code}
                            control={<Radio />}
                            label={
                              <div className={classes.menuList}>
                                <ListItemIcon>{integration.icon}</ListItemIcon>
                                <ListItemText
                                  id={integration.id}
                                  primary={integration.name}
                                />
                              </div>
                            }
                          />
                        </ListItem>
                      );
                    })}
                  </RadioGroup>
                </List>
              </div>
            </div>
            <Divider />
            <div className={classes.actionsGroup}>
              <Button
                ref={this.menuRef}
                variant="outlined"
                className={classes.createListBtn}
                onClick={this.handleCreateNewIntegration}
              >
                Create new integration
              </Button>
              <Button
                autoFocus
                className={classes.saveBtn}
                onClick={this.handleSave}
              >
                <If condition={this.state.isSaving}>
                  <CircularProgress className={classes.spinIcon} size={15} />
                </If>
                Save
              </Button>
            </div>
          </DialogContent>
        </Dialog>
      </>
    );
  }
}

MoveProductToIntegrationListDialog.defaultProps = {
  pages: [],
};

MoveProductToIntegrationListDialog.propTypes = {
  classes: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  product: PropTypes.any.isRequired,
  getIntegrationPages: PropTypes.func.isRequired,
  getProductIntegrations: PropTypes.func.isRequired,
  pages: PropTypes.array,
  addProductToIntegration: PropTypes.func.isRequired,
  enqueueSnackbar: PropTypes.func.isRequired,
};

export default compose(
  connect(mapStateToProps, mapDispatchToProps),
  withStyles(styles),
)(MoveProductToIntegrationListDialog);
