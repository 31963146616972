import React from 'react';
import PropTypes from 'prop-types';
import { withStyles } from '@material-ui/core/styles';
import cs from 'classnames';
import { connect } from 'react-redux';
import { createStructuredSelector } from 'reselect';
import { selectOneIfNotProvided } from 'common/helpers/select';
import {
  Card,
  CardActionArea,
  CardContent,
  CardActions,
  Typography,
} from '@material-ui/core';
import { colors } from 'common/constants';
import { SaveButtonHeart } from './Buttons';
import ProductImage from './ProductImage';
import MoveProductToIntegrationListDialog from '../Dialogs/MoveProductToIntegrationListDialog';

const styles = (theme) => ({
  card: {
    display: 'flex',
    flexDirection: 'column',
    position: 'relative',
    backgroundColor: 'rgb(255, 255, 255)',
    textAlign: 'left',
    boxSizing: 'initial',
    borderRadius: '8px !important',
    borderWidth: '1px !important',
    borderStyle: 'solid !important',
    borderColor: 'rgb(224, 224, 224)',
    borderImage: 'initial',
    transition: 'box-shadow 0.5s ease -in -out 0s',

    '&:hover': {
      boxShadow: '0 4px 20px 0 rgba(0,0,0,0.1)',
    },
  },
  actionArea: {
    '&:hover $focusHighlight': {
      opacity: 0,
    },
  },
  focusHighlight: {
    '&:hover': {
      boxShadow: '0 4px 20px 0 rgba(0,0,0,0.1)',
    },
  },
  cardActions: {
    position: 'relative',
    display: 'flex',
    flexFlow: 'wrap',
    cursor: 'pointer',
    alignItems: 'center',
    padding: theme.spacing(0, 1, 1, 1),
    justifyContent: 'space-between',
  },
  cardContent: {
    padding: theme.spacing(2),
  },
  typography: {
    flexGrow: 1,
    flexDirection: 'column',
    alignItems: 'stretch',
    padding: '0px 4px 4px',
  },
  vendorProduct: {
    fontSize: '14px !important',
    lineHeight: '18px !important',
    fontWeight: 'bold',
    textTransform: 'uppercase',
    letterSpacing: '0.4px',
    color: theme.palette.primary.main,
  },
  isDiscard: {
    filter: 'grayscale(100%)',
  },
  titleProduct: {
    fontWeight: 500,
    fontSize: '14px !important',
    lineHeight: '1.5em !important',
    letterSpacing: '0.6px !important',
    maxHeight: '42px !important',
    textOverflow: 'ellipsis !important',
    display: '-webkit-box !important',
    overflow: 'hidden !important',
    color: 'rgb(72, 72, 72) !important',
    WebkitLineClamp: 2,
    WebkitBoxOrient: 'vertical',
    height: "42px",
  },
  tagsProductWrapper: {
    position: 'absolute',
    left: theme.spacing(2),
    top: theme.spacing(2),
  },
  tagsProduct: {
    borderRadius: theme.spacing(0.5),
    padding: '3px 12px',
    fontWeight: 500,
    fontSize: '14px !important',
    background: theme.palette.primary.main,
    color: '#FFF !important',
    marginBottom: '5px !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px !important',
      lineHeight: '16px !important',
    },
  },
  priceProduct: {
    fontWeight: 600,
    fontSize: '14px !important',
    lineHeight: '16px !important',
    color: '#7C7C7C !important',
    marginBottom: '3px !important',
    textDecoration: 'line-through',
    [theme.breakpoints.down('sm')]: {
      fontSize: '13px !important',
      lineHeight: '15px !important',
    },
  },
  priceProductPromotion: {
    fontWeight: 700,
    fontSize: '16px !important',
    lineHeight: '20px !important',
    color: theme.palette.secondary.main,
    marginBottom: '4px !important',
    [theme.breakpoints.down('sm')]: {
      fontSize: '14px !important',
      lineHeight: '18px !important',
    },
  },
  rating: {
    margin: '0 !important',
  },
});

class ProductCard extends React.Component {
  constructor(props) {
    super(props);
  }

  state = {
    isOpenDialog: false,
  };

  static defaultProps = {
    onClick: () => null,
    product: {},
    index: 0,
    isDiscard: false,
    footer: null,
    isShowHeart: true,
  };

  static propTypes = {
    product: PropTypes.object,
    onClick: PropTypes.func,
    classes: PropTypes.object.isRequired,
    index: PropTypes.number,
    isDiscard: PropTypes.bool,
    footer: PropTypes.any,
    isShowHeart: PropTypes.bool,
  };

  handleClickCard = () => {
    this.props.onClick(this.props.index);
  };

  onOpenMoveDialog = () => {
    this.setState({
      isOpenDialog: true,
    });
  };
  onCloseMoveDialog = (evt) => {
    if (evt) {
      evt.stopPropagation();
      evt.preventDefault();
    }

    this.setState({
      isOpenDialog: false,
    });
  };

  render() {
    const { classes, product, isDiscard, footer, isShowHeart } = this.props;

    return (
      !!product && (
        <>
          <Card className={classes.card} onClick={this.handleClickCard}>
            <CardActionArea
              classes={{
                root: classes.actionArea,
                focusHighlight: classes.focusHighlight,
              }}
            >
              <div className={cs({ [classes.isDiscard]: isDiscard })}>
                <ProductImage product={product} />
              </div>

              <div className={classes.tagsProductWrapper}>
                {product.tags && product.tags.length > 0 && (
                  <Typography
                    variant="body1"
                    className={cs(classes.tagsProduct, {
                      [classes.isDiscard]: isDiscard,
                    })}
                  >
                    {product.tags[0]}
                  </Typography>
                )}
              </div>
              <CardContent className={classes.cardContent}>
                <Typography
                  className={classes.vendorProduct}
                  gutterBottom
                  variant="h3"
                >
                  {product.store}
                </Typography>
                <Typography className={classes.titleProduct} variant="h2">
                  {product.title}
                </Typography>
              </CardContent>
            </CardActionArea>

            <CardActions className={classes.cardActions}>
              <div className={classes.typography}>
                <Typography variant="body1" className={classes.priceProduct}>
                  ${product.price && product.price.min}
                </Typography>
                <Typography
                  variant="body1"
                  className={classes.priceProductPromotion}
                  style={{
                    color: product.promotion
                      ? `${colors.secondary}`
                      : `${colors.black}`,
                  }}
                >
                  Total ${product.price && product.price.min}
                </Typography>
              </div>
              <If condition={isShowHeart}>
                <SaveButtonHeart
                  product={product}
                  isDiscard={isDiscard}
                  onOpenMoveDialog={this.onOpenMoveDialog}
                />
              </If>
            </CardActions>
            {footer}
          </Card>
          <If condition={this.state.isOpenDialog}>
            <MoveProductToIntegrationListDialog
              product={product}
              isOpen={this.state.isOpenDialog}
              handleClose={this.onCloseMoveDialog}
            />
          </If>
        </>
      )
    );
  }
}

const mapStateToProps = createStructuredSelector({
  product: selectOneIfNotProvided('products', 'product', 'productID'),
});

export default connect(mapStateToProps, null)(withStyles(styles)(ProductCard));
