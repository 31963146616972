import React from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { SButton } from './components';

const StyledButton = styled(SButton)`
  background: transparent !important;
  color: #5063f0 !important;
  border: 1px solid #5063f0 !important;
  :hover {
    background: transparent !important;
    color: #1f3bdf !important;
    border: 1px solid #1f3bdf !important;
  }
`;
const PrimaryButton = (props) => {
  return <StyledButton {...props}>{props.children}</StyledButton>;
};

PrimaryButton.defaultProps = {
  children: PropTypes.any,
};

PrimaryButton.propTypes = {
  children: PropTypes.any,
};

export default PrimaryButton;
