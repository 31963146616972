import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import GoogleLogin from 'react-google-login';
import { GOOGLE_LOGIN_CLIENT_ID } from 'config';
import GoogleIcon from '../../icons/google';

const styles = (theme) => ({
  googleBtn: {
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 20px',
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    borderRadius: '8px',
    outline: 'none',
    minWidth: '260px',
    width: '100%',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgb(233, 233, 253)',
    },
  },
  googleBtnText: {
    // color: '#3F51B5',
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    marginLeft: '10px',
    fontSize: '14px',
  },
});

class GoogleLoginBtn extends Component {
  render() {
    const { classes, onSuccess, onFailure } = this.props;

    return (
      <GoogleLogin
        clientId={GOOGLE_LOGIN_CLIENT_ID}
        render={(renderProps) => (
          <button className={classes.googleBtn} onClick={renderProps.onClick}>
            <GoogleIcon />
            <span className={classes.googleBtnText}>CONTINUE WITH GOOGLE</span>
          </button>
        )}
        onSuccess={onSuccess}
        onFailure={onFailure}
      />
    );
  }
}

GoogleLoginBtn.defaultProps = {
  onSuccess: () => null,
  onFailure: () => null,
};

GoogleLoginBtn.propTypes = {
  onSuccess: PropTypes.func,
  onFailure: PropTypes.func,
  classes: PropTypes.object.isRequired,
};

export default withStyles(styles)(GoogleLoginBtn);
