import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { withStyles } from '@material-ui/core/styles';
import { useDispatch, useSelector } from 'react-redux';
import {
  addToDiscards,
  addToFavourites,
  removeFromFavourites,
  removeFromDiscards,
} from 'common/store/actions';
import { defaultTo, eqProps, find, pipe } from 'ramda';

import IconButton from '@material-ui/core/IconButton';
import FavoriteIcon from '@material-ui/icons/Favorite';
import FavoriteBorderIcon from '@material-ui/icons/FavoriteBorder';
import { getSavedProducts } from 'common/store/selector/entities';

import SpeedDial from '@material-ui/lab/SpeedDial';
import SpeedDialAction from '@material-ui/lab/SpeedDialAction';
import DeleteIcon from '@material-ui/icons/Delete';
import EditIcon from '@material-ui/icons/Edit';
import MoveToInboxIcon from '@material-ui/icons/MoveToInbox';
import Tooltip from '@material-ui/core/Tooltip';

const styles = (theme) => ({
  speedDial: {
    '&:hover': {
      '& > button': {
        border: '1px solid #3F51B5',
        color: theme.palette.primary.main,
      },
    },
    position: 'absolute',
    bottom: '12px',
    right: '50px',
    '& > button': {
      color: '#bdbdbd',
      backgroundColor: '#fff',
      padding: '8px !important',
      width: '38px',
      height: '38px',
      boxShadow: 'none',
      border: '1px solid #bdbdbd',

      '&:hover': {
        backgroundColor: 'rgb(233, 233, 253)',
        color: theme.palette.primary.main,
        border: '1px solid #3F51B5',
      },
    },
    '& .MuiSpeedDial-actions': {
      '& button': {
        color: theme.palette.primary.main,
        '&:hover': {
          backgroundColor: 'rgb(233, 233, 253)',
        },
      },
    },
  },
  isDiscard: {
    color: 'gray'
  }
});

const SaveButtonHeart = (props) => {
  const { classes, product, isDiscard } = props;

  const favoriteProducts = useSelector(getSavedProducts);
  const [open, setOpen] = React.useState(false);

  const isFavourite = useMemo(() => {
    return pipe(
      defaultTo([]),
      find(eqProps('id', product)),
      Boolean,
    )(favoriteProducts);
  }, [favoriteProducts, product]);
  const dispatch = useDispatch();

  const handleFavourite = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    if (isFavourite) {
      dispatch(removeFromFavourites(product.id));
    } else {
      if (isDiscard) {
        dispatch(removeFromDiscards(product.id));
      }
      dispatch(
        addToFavourites({
          id: product.id,
          type: product.type,
        }),
      );
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleDelete = async (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    if (isDiscard) {
      dispatch(removeFromDiscards(product.id));
    } else {
      dispatch(removeFromFavourites(product.id));
      dispatch(addToDiscards(product.id));
    }

  };

  const handleEdit = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
  };

  const handleMove = (evt) => {
    evt.stopPropagation();
    evt.preventDefault();
    if (!open) {
      setOpen(true);
    } else {
      props.onOpenMoveDialog();
    }
  };

  const actions = [
    { icon: <DeleteIcon onClick={handleDelete} className={classnames({[classes.isDiscard]: isDiscard})}/>, name: 'Delete' },
    { icon: <EditIcon onClick={handleEdit} />, name: 'Edit' },
  ];

  return (
    <Fragment>
      <Choose>
        <When condition={isFavourite || isDiscard}>
          <SpeedDial
            ariaLabel="SpeedDial example"
            className={classes.speedDial}
            icon={
              <Tooltip title="Move" aria-label="move" placement="left-start">
                <MoveToInboxIcon onClick={handleMove} />
              </Tooltip>
            }
            onClose={handleClose}
            onBlur={handleClose}
            onOpen={handleOpen}
            open={open}
            direction="up"
          >
            {actions.map((action) => (
              <SpeedDialAction
                key={action.name}
                icon={action.icon}
                tooltipTitle={action.name}
                onClick={handleClose}
              />
            ))}
          </SpeedDial>
        </When>
      </Choose>
      <IconButton
        className={
          isFavourite
            ? 'button button-active--test'
            : 'button button-inactive--test'
        }
        aria-label="Add to favorites"
        onClick={handleFavourite}
      >
        <Choose>
          <When condition={isFavourite}>
            <FavoriteIcon className={'icon icon-active--test'} />
          </When>
          <Otherwise>
            <FavoriteBorderIcon className={'icon icon-inactive--test'} />
          </Otherwise>
        </Choose>
      </IconButton>
    </Fragment>
  );
};

SaveButtonHeart.defaultProps = {
  product: {},
  isDiscard: false,
  onOpenMoveDialog: () => null,
};

SaveButtonHeart.propTypes = {
  product: PropTypes.object,
  classes: PropTypes.object.isRequired,
  isDiscard: PropTypes.bool,
  onOpenMoveDialog: PropTypes.func,
};

export default withStyles(styles)(SaveButtonHeart);
