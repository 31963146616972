import { createSelector } from 'reselect';
import { __, defaultTo, map, pipe, prop, propOr, values } from 'ramda';
import { selectProductEntities } from './entities';
import memoizeOne from 'memoize-one';

export const selectEntities = (state) => state.integration;

export const selectIntegrationList = createSelector(
  selectEntities,
  (integration) => integration.entities,
);

export const selectSelectedProductIntegrationList = createSelector(
  selectEntities,
  (integration) => integration.selected,
);

export const selectIntegrationPages = createSelector(
  selectIntegrationList,
  (integrationList) => {
    const listData = values(integrationList);

    return listData.map((item) => {
      return { id: item.id, code: item.code };
    });
  },
);

export const selectIntegrationByCode = createSelector(
  selectIntegrationList,
  selectProductEntities,
  (integrations, products) => {
    return memoizeOne((code) => {
      const productIds = propOr(
        [],
        'entries',
        values(integrations).find((integration) => integration.code === code),
      );

      return pipe(defaultTo([]), map(prop(__, products)))(productIds);
    });
  },
);

export const selectSelectedProductsIntegration = createSelector(
  selectSelectedProductIntegrationList,
  (selectedList) => {
    return memoizeOne((id) => {
      return propOr([], id, selectedList);
    });
  },
);
