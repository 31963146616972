import styled from 'styled-components';
import Button from '@material-ui/core/Button';

export const GoogleButton = styled(Button)`
`;

export const SButton = styled(Button)`
    width: ${props => props.block ? '100%' : 'auto'};
    font-size:17px !important;
    font-weight:600 !important;
    text-transform:capitalize !important;
    border-radius:7px!important;
`;
