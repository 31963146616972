import React, { Fragment, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/styles';
import { addToFavourites, removeFromFavourites } from 'common/store/actions';
import { DefaultButton } from 'common/components/Buttons';

import FavoriteIcon from '@material-ui/icons/Favorite';
import { getSavedProducts } from 'common/store/selector/entities';
import { defaultTo, eqProps, pipe, find } from 'ramda';

// eslint-disable-next-line no-unused-vars
const useStyles = makeStyles((theme) => ({
  buttonSize: {
    margin: theme.spacing(2, 0, 3),
  },
}));

const SaveButton = ({ product }) => {
  const favoriteProducts = useSelector(getSavedProducts);
  const isFavourite = useMemo(() => {
    return pipe(
      defaultTo([]),
      find(eqProps('id', product)),
      Boolean,
    )(favoriteProducts);
  }, [favoriteProducts, product]);
  const dispatch = useDispatch();

  const toggleFavourite = (evt) => {
    evt.preventDefault();
    if (isFavourite) {
      dispatch(removeFromFavourites(product.id));
    } else {
      dispatch(
        addToFavourites({
          id: product.id,
          type: product.type,
        }),
      );
    }
  };

  return (
    <Fragment>
      <DefaultButton
        onClick={toggleFavourite}
        icon={<FavoriteIcon />}
        on={isFavourite}
        block="true"
      >
        Favorite
      </DefaultButton>
    </Fragment>
  );
};

SaveButton.defaultProps = {
  product: {},
};

SaveButton.propTypes = {
  product: PropTypes.object,
};

export default SaveButton;

// import React from 'react';
// import { useDispatch } from 'react-redux';

// import { cancelBorrowRequest, returnBookRequest } from '@redux/actions/lendingActions';

// import { Button } from '@styles/common';

// const LendingButton = ({ currentStatus }) => {
//   const dispatch = useDispatch();

//   const handleCancel = () => {
//     dispatch(cancelBorrowRequest(data.id, 'myRequests'));
//   };
//   const handleReturn = () => {
//     dispatch(returnBookRequest(data.id, 'myRequests'));
//   };

//   if (currentStatus === 'REQUESTED') {
//     return (
//       <Button _color='red' onClick={handleCancel}>
//         취소
//       </Button>
//     );
//   }
//   if (currentStatus === 'ACCEPTED') {
//     return (
//       <Button _color='secondary' onClick={handleReturn}>
//         반납
//       </Button>
//     );
//   }
//   return null;
// };

// export default LendingButton;

// import React, { useEffect } from 'react';
// import Link from 'next/link';
// import Router from 'next/router';
// import { useDispatch, useSelector } from 'react-redux';

// import { useInput } from '@utils/inputUtils';
// import { signInRequest } from '@redux/actions/userActions';

// import { CenterDiv, InputGroup, Button, LoadingIcon, CenterForm, ButtonLink } from '@styles/common';

// const SignInPage = () => {
//   const { isSignedIn, isLoading } = useSelector(state => state.user.user);
//   const { data } = useSelector(state => state.user.join);
//   const [username, usernameHandler] = useInput(data && data.email);
//   const [password, passwordHandler] = useInput();
//   const dispatch = useDispatch();

//   useEffect(() => {
//     if (isSignedIn) {
//       const referrer = document.referrer;
//       const origin = document.location.origin;

//       if (referrer.startsWith(origin)) {
//         Router.back();
//       } else {
//         Router.push('/');
//       }
//     }
//   }, [isSignedIn]);

//   const handleSubmit = e => {
//     e.preventDefault();
//     dispatch(signInRequest({ username: username.trim().toLowerCase(), password }));
//   };

//   return (
//     <CenterDiv>
//       <h1 className='title'>로그인</h1>
//       <CenterForm onSubmit={handleSubmit}>
//         <InputGroup>
//           <label htmlFor='username'>이메일</label>
//           <input
//             required
//             id='username'
//             type='email'
//             value={username}
//             onChange={usernameHandler}
//             placeholder='your@email.com'
//           />
//         </InputGroup>
//         <InputGroup>
//           <label htmlFor='password'>비밀번호</label>
//           <input
//             required
//             id='password'
//             type='password'
//             value={password}
//             onChange={passwordHandler}
//           />
//         </InputGroup>
//         <CenterDiv>
//           {isLoading ? (
//             <Button _color='primary' type='submit' disabled>
//               <LoadingIcon _size='14px' className='material-icons'>
//                 autorenew
//               </LoadingIcon>
//             </Button>
//           ) : (
//               <Button _color='primary' type='submit'>
//                 로그인
//             </Button>
//             )}
//         </CenterDiv>
//         <CenterDiv>
//           <Link href='/join' prefetch={false}>
//             <ButtonLink>아직 회원이 아니신가요?</ButtonLink>
//           </Link>
//         </CenterDiv>
//       </CenterForm>
//     </CenterDiv>
//   );
// };

// export default SignInPage;
