import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withStyles } from '@material-ui/core/styles';

import FacebookLogin from 'react-facebook-login/dist/facebook-login-render-props';

import { FACEBOOK_LOGIN_CLIENT_ID } from 'config';
import FacebookIcon from '../../icons/facebook';

const styles = (theme) => ({
  facebookBtn: {
    height: '48px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    padding: '10px 20px',
    border: '1px solid',
    borderColor: theme.palette.secondary.main,
    borderRadius: '8px',
    outline: 'none',
    minWidth: '260px',
    width: '100%',
    '&:hover': {
      cursor: 'pointer',
      backgroundColor: 'rgb(233, 233, 253)',
    },
  },
  facebookBtnText: {
    color: theme.palette.secondary.main,
    fontWeight: 'bold',
    marginLeft: '10px',
    fontSize: '14px',
  },
});

class FaceBookLoginBtn extends Component {
  render() {
    const { classes, onSuccess } = this.props;
    return (
      <FacebookLogin
        appId={FACEBOOK_LOGIN_CLIENT_ID}
        callback={onSuccess}
        render={(renderProps) => (
          <button className={classes.facebookBtn} onClick={renderProps.onClick}>
            <FacebookIcon />
            <span className={classes.facebookBtnText}>
              CONTINUE WITH FACEBOOK
            </span>
          </button>
        )}
      />
    );
  }
}

FaceBookLoginBtn.defaultProps = {
  onSuccess: () => null,
};

FaceBookLoginBtn.propTypes = {
  classes: PropTypes.object.isRequired,
  onSuccess: PropTypes.func,
};

export default withStyles(styles)(FaceBookLoginBtn);
